/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import 'fontsource-ibm-plex-sans';
// import 'fontsource-ibm-plex-serif';
import 'fontsource-ibm-plex-mono';
import './src/styles/global.css';
