// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-oxalate-js": () => import("./../../../src/pages/about-oxalate.js" /* webpackChunkName: "component---src-pages-about-oxalate-js" */),
  "component---src-pages-about-this-site-js": () => import("./../../../src/pages/about-this-site.js" /* webpackChunkName: "component---src-pages-about-this-site-js" */),
  "component---src-pages-all-foods-js": () => import("./../../../src/pages/all-foods.js" /* webpackChunkName: "component---src-pages-all-foods-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-group-js": () => import("./../../../src/templates/Group.js" /* webpackChunkName: "component---src-templates-group-js" */),
  "component---src-templates-rating-js": () => import("./../../../src/templates/Rating.js" /* webpackChunkName: "component---src-templates-rating-js" */)
}

